import { Box, BoxProps, Icon, IconProps } from '@cloudscape-design/components';

export type SentimentIconProps = {
    score?: number;
}

export const SentimentIcon = ({ score }: Readonly<SentimentIconProps>) => {

  const boxProps: BoxProps = {
    color: 'text-status-inactive',
    variant: 'p'
  }

  const iconProps: IconProps = {
    name: 'face-neutral'
  }

  if(Number(score) > 1) {
    boxProps.color = 'text-status-success';
    iconProps.name = 'face-happy';
  
  } else if(Number(score) < -1) {
    boxProps.color = 'text-status-error';
    iconProps.name = 'face-sad';
  }

  return (<Box {...boxProps}><Icon {...iconProps} /> {score}</Box>);
};
