import { StatusIndicatorProps } from "@cloudscape-design/components";

export const getStatusIndicatorType = (status: string, state: string): StatusIndicatorProps.Type => {

  if (state === 'CONNECTED' || state === 'CONNECTED_ONHOLD') {
    return 'info';
  }

  if(status !== 'ROUTABLE') {
    return 'warning';
  }

  if (state === 'ENDED') {
    return 'pending';
  }

  if(state === 'MISSED') {
    return 'error';
  }

  return 'success';
};
