import { queueArnsToQueueIds } from '../routes/visualizations/view/dashboard/utils/widget.helpers';
import { WidgetDataRepresentation } from '../routes/visualizations/view/dashboard/widgets/interfaces';
import useIntuitionSWR from './hooks/useIntuitionSWR';
import { formatDateTimePickerApi } from './timeUtils';

const oneMinute = 60000;

export default function GetWidgetData<Response=any>({ widget, period }: { widget: WidgetDataRepresentation<any>; period: any }) {
  const { templateType } = widget;

  const {channels, queues} = widget.filters || {}

  const { startDate, endDate } = formatDateTimePickerApi(period);
  const interval = period.type === 'relative' ? oneMinute : 0;

  const params = new URLSearchParams({
    start: startDate,
    end: endDate,
    templateType
  });

  if (queues) params.append('queues', queueArnsToQueueIds(queues));
  if (channels) params.append('channels', JSON.stringify(channels));

  const url = `/queries?${params.toString()}`;
  const { data, isLoading, error } = useIntuitionSWR<Response>(url, {
    shouldRetryOnError: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: interval,
  });

  return { data, isLoading, error };
}
