import { CollectionPreferencesProps, TableProps } from '@cloudscape-design/components';
import { secondsToHHMM } from '../../../../../../utils/timeUtils';
import { AgentSummaryStat } from './types';
import { PreferenceProps } from '../../../../../../types/table';
import { TablePreferences } from '../../../../../../utils/tablePreferences';
import { SentimentIcon } from '../../components/sentiment';

export const tableColumns: TableProps.ColumnDefinition<AgentSummaryStat>[] = [
  {
    id: 'username',
    header: 'Username',
    cell: (item) => item.username,
    width: 200,
    minWidth: 176,
    isRowHeader: true,
    sortingField: 'username',
  },
  {
    id: 'no_of_contactIds',
    header: 'Handled',
    cell: (item) => item.no_of_contactIds,
    width: 200,
    minWidth: 176,
    isRowHeader: true,
    sortingField: 'no_of_contactIds',
  },
  {
    id: 'agent_missed_contacts',
    header: 'Missed contacts',
    cell: (item) => item.agent_missed_contacts,
    width: 200,
    minWidth: 176,
    isRowHeader: true,
    sortingField: 'agent_missed_contacts',
  },
  {
    id: 'average_talk_time',
    header: 'AHT',
    cell: (item) => secondsToHHMM(item.average_talk_time),
    width: 200,
    minWidth: 176,
    isRowHeader: true,
    sortingField: 'average_talk_time',
  },
  {
    id: 'average_agentAfterContactWorkDuration',
    header: 'ACW',
    cell: (item) => secondsToHHMM(item.average_agentAfterContactWorkDuration),
    width: 200,
    minWidth: 176,
    isRowHeader: true,
    sortingField: 'average_agentAfterContactWorkDuration',
  },
  {
    id: 'agentNumberOfHoldsCount',
    header: 'No. Holds',
    cell: (item) => item.agentNumberOfHoldsCount,
    width: 200,
    minWidth: 176,
    isRowHeader: true,
    sortingField: 'agentNumberOfHoldsCount',
  },
  {
    id: 'agentCustomerHoldDurationTotal',
    header: 'Hold Duration',
    cell: (item) => secondsToHHMM(item.agentCustomerHoldDurationTotal),
    width: 200,
    minWidth: 176,
    isRowHeader: true,
    sortingField: 'agentCustomerHoldDurationTotal',
  },
  {
    id: 'average_customer_sentiment',
    header: 'Customer Sentiment',
    cell: (item) => <SentimentIcon {...{ score: item.average_customer_sentiment }} />,
    width: 200,
    minWidth: 176,
    isRowHeader: true,
    sortingField: 'average_customer_sentiment',
  },
  {
    id: 'average_agent_sentiment',
    header: 'Agent Sentiment',
    cell: (item) => <SentimentIcon {...{ score: item.average_agent_sentiment }} />,
    width: 200,
    minWidth: 176,
    isRowHeader: true,
    sortingField: 'average_agent_sentiment',
  },
  {
    id: 'percent_transferred',
    header: '% transferred',
    cell: (item) => item.percent_transferred,
    width: 200,
    minWidth: 176,
    isRowHeader: true,
    sortingField: 'percent_transferred',
  },
];

const CONTENT_DISPLAY_OPTIONS = [
  { id: 'username', label: 'Username', alwaysVisible: true },
  { id: 'no_of_contactIds', label: 'Handled', alwaysVisible: false },
  { id: 'agent_missed_contacts', label: 'Missed Contacts', alwaysVisible: false },
  { id: 'average_talk_time', label: 'AHT', alwaysVisible: false },
  { id: 'average_agentAfterContactWorkDuration', label: 'ACW', alwaysVisible: false },
  { id: 'agentNumberOfHoldsCount', label: 'Holds', alwaysVisible: false },
  { id: 'agentCustomerHoldDurationTotal', label: 'Hold Duration', alwaysVisible: false },
  { id: 'average_customer_sentiment', label: 'Customer Sentiment', alwaysVisible: false },
  { id: 'average_agent_sentiment', label: 'Agent Sentiment', alwaysVisible: false },
  { id: 'percent_transferred', label: '% Transferred', alwaysVisible: false },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 rows' },
  { value: 30, label: '30 rows' },
  { value: 50, label: '50 rows' },
];

export const DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  pageSize: 10,
  contentDisplay: [
    { id: 'username', visible: true },
    { id: 'no_of_contactIds', visible: true },
    { id: 'agent_missed_contacts', visible: true },
    { id: 'average_talk_time', visible: true },
    { id: 'average_agentAfterContactWorkDuration', visible: true },
    { id: 'agentNumberOfHoldsCount', visible: true },
    { id: 'agentCustomerHoldDurationTotal', visible: true },
    { id: 'average_customer_sentiment', visible: true },
    { id: 'average_agent_sentiment', visible: true },
    { id: 'percent_transferred', visible: true },
  ],
  wrapLines: false,
  stripedRows: false,
  contentDensity: 'compact',
  stickyColumns: { first: 1, last: 0 },
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled = false,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
}: PreferenceProps) => <TablePreferences {...{ preferences, setPreferences, disabled, pageSizeOptions, contentDisplayOptions }} />;
