import { Auth } from 'aws-amplify';

export type TokenPayload = {
  accessToken: string;
  username: string;
  idToken: string;
  refreshToken: string;
};

export class IdentityProviderSignIn {
  private readonly storage: Storage;
  private readonly window: Window;
  private readonly auth: typeof Auth;
  private readonly messageHandler: (event: MessageEvent) => void;

  constructor(win: Window = window, storage: Storage = localStorage, authProvider: typeof Auth = Auth) {
    this.window = win;
    this.storage = storage;
    this.auth = authProvider;
    this.messageHandler = this.loginMessageReceiver.bind(this);
  }

  public registerSignInListener(): void {
    this.window.addEventListener('message', this.messageHandler);
  }

  public unregisterSignInListener(): void {
    this.window.removeEventListener('message', this.messageHandler);
  }

  public async parentMessageSender(): Promise<void> {
    try {
      const userSession = await this.auth.currentSession();
      const idToken = userSession.getIdToken().getJwtToken();
      const accessToken = userSession.getAccessToken().getJwtToken();
      const username = userSession.getAccessToken().payload.username;
      const refreshToken = userSession.getRefreshToken().getToken();

      this.window.opener?.postMessage({ accessToken, idToken, refreshToken, username });
      this.window.close();
    } catch (error) {

    }
  }

  public loginMessageReceiver(event: MessageEvent): void {
    if (event.origin !== this.window.location.origin) return;

    try {
      this.isTokenPayload(event.data);

      const inIframe = this.window.self !== this.window.top;

      if (inIframe) {
        this.saveTokensToLocalStorage(event.data);
      }

      this.window.location.reload();
      this.unregisterSignInListener();
    } catch (error) {
      
    }
  }

  private saveTokensToLocalStorage(tokens: TokenPayload): void {
    const keyPrefix = `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_WEB_CLIENT_ID}.${tokens.username}`;

    this.storage.setItem(`${keyPrefix}.idToken`, tokens.idToken);
    this.storage.setItem(`${keyPrefix}.accessToken`, tokens.accessToken);
    this.storage.setItem(`${keyPrefix}.refreshToken`, tokens.refreshToken);
    this.storage.setItem(`CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_WEB_CLIENT_ID}.LastAuthUser`, tokens.username);
    this.storage.setItem(`amplify-redirected-from-hosted-ui`, 'true');
    this.storage.setItem(`amplify-signin-with-hostedUI`, 'true');
  }

  private isTokenPayload(data: any): asserts data is TokenPayload {
    const dataIsTokenPayload =
      typeof data === 'object' && data !== null && 'accessToken' in data && 'idToken' in data && 'refreshToken' in data && 'username' in data;
    if (!dataIsTokenPayload) {
      throw new Error('Invalid data type');
    }
  }
}
