import { useContext, useEffect } from 'react';
import GetWidgetData from '../../../../../../utils/getWidgetData';
import { useDashboardContext } from '../../context/dashboardContext';
import { useDashboardManager } from '../../hooks/useDashboardManager';
import { WidgetContentProps } from '../interfaces';
import WidgetTable from './table';
import { AgentSummaryStatsWidgetContext } from '.';
import { AgentSummaryStats } from './types';
import { ApiResponse } from '../../../../../../types/api';

export default function AgentSummaryStatsContent({ widgetId }: WidgetContentProps) {
  const { getWidget } = useDashboardManager();
  const { dashboard } = useDashboardContext();
  const { setAgentCount } = useContext(AgentSummaryStatsWidgetContext);

  const widget = getWidget(widgetId);
  const period = dashboard.data.period;

  const { data, isLoading, error } = GetWidgetData<ApiResponse<AgentSummaryStats>>({ widget, period });

  const widgetTableProps = {
    widgetId,
    data: data?.data ?? [],
    isLoading,
    error,
  };

  useEffect(() => setAgentCount(data?.data?.length ?? 0), [data?.data]);

  return <WidgetTable {...widgetTableProps} />;
}
