export const relativeRangeLabels: { [key: string]: string } = {
  'minute-5': 'Last 5 minutes',
  'minute-30': 'Last 30 minutes',
  'hour-1': 'Last 1 hour',
  'hour-6': 'Last 6 hours',
  'day-3': 'Last 3 days',
  'day-7': 'Last 7 days',
  'day-14': 'Last 14 days',
  'day-30': 'Last 30 days',
  'day-1': 'Today',
  'week-1': 'Current week',
  'month-1': 'Current month',
  'year-1': 'Current year',
};
