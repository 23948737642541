export const FILTERING_PROPERTIES = [
  {
    propertyLabel: 'Username',
    key: 'currentAgentSnapshotConfigurationUsername',
    groupValuesLabel: 'Username',
    operators: [':', '!:', '=', '!='] as const,
  },
  {
    propertyLabel: 'First Name',
    key: 'currentAgentSnapshotConfigurationFirstName',
    groupValuesLabel: 'First Name',
    operators: [':', '!:', '=', '!='] as const,
  },
  {
    propertyLabel: 'Last Name',
    key: 'currentAgentSnapshotConfigurationLastName',
    groupValuesLabel: 'Last Name',
    operators: [':', '!:', '=', '!='] as const,
  },
  {
    propertyLabel: 'Event Type',
    key: 'eventType',
    groupValuesLabel: 'Event Type',
    operators: [':', '!:', '=', '!='] as const,
  },
  {
    propertyLabel: 'AutoAccept',
    key: 'currentAgentSnapshotConfigurationAutoAccept',
    groupValuesLabel: 'AutoAccept',
    operators: [':', '!:', '=', '!='] as const,
  },
  {
    propertyLabel: 'Status',
    key: 'currentAgentSnapshotAgentStatusName',
    groupValuesLabel: 'Status',
    operators: [':', '!:', '=', '!='] as const,
  },
  {
    propertyLabel: 'Contact State',
    key: 'currentAgentSnapshotFirstContactState',
    groupValuesLabel: 'Contact State',
    operators: [':', '!:', '=', '!='] as const,
  },
  {
    propertyLabel: 'Next Agent State',
    key: 'currentAgentSnapshotNextAgentStatus',
    groupValuesLabel: 'Next Agent State',
    operators: [':', '!:', '=', '!='] as const,
  },
  {
    propertyLabel: 'Contact Queue Name',
    key: 'currentAgentSnapshotFirstContactQueueName',
    groupValuesLabel: 'Contact Queue Name',
    operators: [':', '!:', '=', '!='] as const,
  },
  {
    propertyLabel: 'Routing Profile',
    key: 'currentAgentSnapshotConfigurationRoutingProfileName',
    groupValuesLabel: 'Routing Profile',
    operators: [':', '!:', '=', '!='] as const,
  },
  {
    propertyLabel: 'Hierarchy L1',
    key: 'currentAgentSnapshotConfigurationAgentHierarchyGroupsL1',
    groupValuesLabel: 'Hierarchy L1',
    operators: [':', '!:', '=', '!='] as const,
  },
  {
    propertyLabel: 'Hierarchy L2',
    key: 'currentAgentSnapshotConfigurationAgentHierarchyGroupsL2',
    groupValuesLabel: 'Hierarchy L2',
    operators: [':', '!:', '=', '!='] as const,
  },
  {
    propertyLabel: 'Hierarchy L3',
    key: 'currentAgentSnapshotConfigurationAgentHierarchyGroupsL3',
    groupValuesLabel: 'Hierarchy L3',
    operators: [':', '!:', '=', '!='] as const,
  },
  {
    propertyLabel: 'Hierarchy L4',
    key: 'currentAgentSnapshotConfigurationAgentHierarchyGroupsL4',
    groupValuesLabel: 'Hierarchy L4',
    operators: [':', '!:', '=', '!='] as const,
  },
  {
    propertyLabel: 'Hierarchy L5',
    key: 'currentAgentSnapshotConfigurationAgentHierarchyGroupsL5',
    groupValuesLabel: 'Hierarchy L5',
    operators: [':', '!:', '=', '!='] as const,
  },
] as const;
