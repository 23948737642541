import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { I18nProvider } from '@cloudscape-design/components/i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Theme, applyTheme } from '@cloudscape-design/components/theming';
import customTheme from './themes/customTheme';
import messages from '@cloudscape-design/components/i18n/messages/all.all';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { router } from './router';
import { authComponents, authFormFields } from './authenticator';
import { IdentityProviderSignIn } from './identityProviderSignIn';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
  },
  oauth: {
    domain: `${process.env.REACT_APP_COGNITO_DOMAIN}.auth.us-east-1.amazoncognito.com`,
    scope: ['email', 'profile', 'openid'],
    redirectSignIn: process.env.REACT_APP_PORTAL_URL,
    redirectSignOut: process.env.REACT_APP_PORTAL_URL,
    responseType: 'code',
  },
});

const hasParentOpenerWindow = window.opener;

const identityProvider = new IdentityProviderSignIn();

if (hasParentOpenerWindow) {
  identityProvider.parentMessageSender();
} else {
  identityProvider.registerSignInListener();
}

const theme: Theme = customTheme;

applyTheme({ theme });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <I18nProvider
      locale='en'
      messages={[messages]}
    >
      <Authenticator
        hideSignUp
        formFields={authFormFields}
        components={authComponents}
      >
        <RouterProvider router={router} />
      </Authenticator>
    </I18nProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
