import { Link } from 'react-router-dom';
import { Badge, CollectionPreferencesProps, TableProps } from '@cloudscape-design/components';
import { useProtectUtility } from '../../../components/protectedUtility';
import { Actions, ResourceTypes } from '../../../types/rolePermissions';
import { ClosureSummary } from '../../../types/closures';
import { PreferenceProps } from '../../../types/table';
import { TagIcon } from '../../utilities/tagIcon';
import { TablePreferences } from '../../../utils/tablePreferences';

export const useTableConfig = () => {
  const protectUtility = useProtectUtility();

  const columns: Array<TableProps.ColumnDefinition<ClosureSummary>> = [
    {
      id: 'feature',
      header: 'Closure Group',
      cell: (item) =>
        protectUtility({ resourceType: ResourceTypes.Closures, resourceAction: Actions.Update }) ? (
          <Link to={`/closures/edit/${item?.feature}`}>{item?.feature}</Link>
        ) : (
          item.feature
        ),
      sortingField: 'feature',
      width: 200,
    },
    {
      id: 'timezone',
      header: 'Timezone',
      cell: (item) => item?.timezone || '-',
      sortingField: 'timezone',
      width: 150,
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item?.description || '-',
      sortingField: 'description',
      width: 200,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => {
        const status = item?.status || '-';

        switch (status) {
          case 'unplannedClosure':
            return <Badge color='red'>Unplanned Closure</Badge>;
          case 'closed':
            return <Badge color='grey'>Closed</Badge>;
          case 'open':
            return <Badge color='green'>Open</Badge>;
          case 'plannedClosure':
            return <Badge color='blue'>Planned Closure</Badge>;
          case 'recurringClosure':
            return <Badge color='blue'>Recurring Closure</Badge>;
          default:
            return '-';
        }
      },
      sortingField: 'status',
      width: 200,
    },
    {
      id: 'message',
      header: 'Active Closure',
      cell: (item) => {
        if (item.status === 'recurringClosure' || item.status === 'plannedClosure') {
          const startTime = item.startTime || '';
          const endTime = item.endTime || '';
          return `${item.closureDescription}: ${startTime} - ${endTime}`;
        } else {
          return item.closureDescription || '-';
        }
      },
      width: 200,
    },
    {
      id: 'tag',
      header: (
        <>
          Tag <TagIcon />
        </>
      ),
      cell: (item) => item.tag ?? '',
      sortingField: 'tag',
    },
  ];

  const defaultContentDisplayOptions: CollectionPreferencesProps.ContentDisplayOption[] = [
    { id: 'feature', label: 'Closure Group', alwaysVisible: true },
    { id: 'timezone', label: 'Timezone', alwaysVisible: false },
    { id: 'description', label: 'Description', alwaysVisible: false },
    { id: 'status', label: 'Status', alwaysVisible: false },
    { id: 'message', label: 'Message', alwaysVisible: false },
    { id: 'tag', label: 'Tag', alwaysVisible: false },
  ];

  const defaultPageSizeOptions: CollectionPreferencesProps.PageSizeOption[] = [
    { value: 10, label: '10 closure groups' },
    { value: 30, label: '30 closure groups' },
    { value: 50, label: '50 closure groups' },
  ];

  const defaultPreferences: CollectionPreferencesProps.Preferences = {
    pageSize: 10,
    contentDisplay: [
      { id: 'feature', visible: true },
      { id: 'timezone', visible: true },
      { id: 'description', visible: true },
      { id: 'status', visible: true },
      { id: 'message', visible: true },
      { id: 'tag', visible: true },
    ],
    wrapLines: false,
    stripedRows: false,
    contentDensity: 'comfortable',
    stickyColumns: { first: 1, last: 0 },
  };

  const Preferences = ({
    preferences,
    setPreferences,
    disabled = false,
    pageSizeOptions = defaultPageSizeOptions,
    contentDisplayOptions = defaultContentDisplayOptions,
  }: PreferenceProps) => <TablePreferences {...{ preferences, setPreferences, disabled, pageSizeOptions, contentDisplayOptions }} />;

  return { columns, defaultPreferences, Preferences };
};
