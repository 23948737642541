import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, ButtonProps, Flex, Heading, TabItem, Tabs, TextField, TextFieldProps } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { Spinner } from '@cloudscape-design/components';
import { useInitialise } from '../../utils/hooks/useInitialise';
import { exchangeUsernameForIdentityProvider, parentMessageReciever, popupInitiation } from './login-helpers';
import styles from './login.module.scss';
function LoginHeader() {

  const [username, setUsername] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = React.useState(false);
  const [identityProvider, setIdentityProvider] = useState<string>('');
  const [isPopUp, setIsPopUp] = useState<boolean>(false);
  const urlPath = window.location.pathname;
  const amplifyFormElementRef = useRef<HTMLElement | null>(null); // Ref for amplify form


  useInitialise(() => {
    Auth.currentAuthenticatedUser()
      .then(() => location.reload())
      .catch((noop) => noop);

    setIsPopUp(urlPath === '/login' && window.opener);

    amplifyFormElementRef.current = document.querySelector('[data-amplify-form]') as HTMLElement;
    if (amplifyFormElementRef.current) {
      amplifyFormElementRef.current.style.display = 'none';
    }
  });

  const updateUsername = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    setHasError(false);
  }, []);

  useEffect(() => {
    if (isPopUp) {
      window.opener.addEventListener('message', parentMessageReciever);
      window.opener.postMessage({ command: 'provider_request' });

      return () => {
        window.opener.removeEventListener('message', parentMessageReciever);
      };
    }
  }, [isPopUp]);

  const getIdentityProvider = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const identityProviderResponse = await exchangeUsernameForIdentityProvider(username);

      setIdentityProvider(identityProviderResponse);
    } catch (e) {
      setHasError(true);
      setIsLoading(false);
    }
  }, [username]);

  useEffect(() => {
    if (identityProvider) {
      popupInitiation(identityProvider);
    }
  }, [identityProvider]);

  const setTab = useCallback((tab: string | number) => {
    if (amplifyFormElementRef.current) {
      amplifyFormElementRef.current.style.display = tab === '1' ? 'block' : 'none';
    }
  }, []);

  const loginFieldProps: TextFieldProps = {
    descriptiveText: 'Enter a valid email',
    placeholder: 'user@example.com',
    label: 'Last name',
    labelHidden: true,
    type: 'email',
    onChange: (e) => updateUsername(e),
    value: username,
    disabled: isLoading,
    errorMessage: `Username "${username}" not found`,
    hasError: hasError,
  };

  const loginButtonProps: ButtonProps = {
    type: 'submit',
    variation: 'primary',
    disabled: isLoading,
  }

  return (
    <>
      <Heading className={styles.header} level={3}>
        <span>Cato</span>
      </Heading>
      <Tabs onChange={(tab) => setTab(tab)}>
        <TabItem title='SSO'>
          <section style={{ borderBottom: '1px solid #CCC' }}>
            <Flex className={styles.ssoLoginContainer}>
              &nbsp;
              {!isPopUp && (
                <Flex
                  as='form'
                  direction='column'
                  onSubmit={(e: React.FormEvent<HTMLFormElement>) => getIdentityProvider(e)}
                >
                  <TextField {...loginFieldProps} />

                  <Button { ...loginButtonProps}>
                    Submit {isLoading && <Spinner />}
                  </Button>
                </Flex>
              )}
              {isPopUp && (
                <Button
                  type='button'
                  variation='primary'
                  disabled={true}
                >
                  Loading &nbsp; <Spinner />
                </Button>
              )}
            </Flex>
          </section>
        </TabItem>
        <TabItem title='standard'></TabItem>
      </Tabs>
    </>
  );
}

export default LoginHeader;
