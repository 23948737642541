import { Auth } from 'aws-amplify';
const MANAGEMENT_URL = process.env.REACT_APP_MNG_API_URL;
export const exchangeUsernameForIdentityProvider = async(username: string): Promise<string> => {
  const domain = username.split('@').pop();
  const requestConfig: RequestInit = {
    method: 'GET',
    headers: {'Content-Type': 'application/json',},
  }
  const response = await fetch(`${MANAGEMENT_URL}/identifier/${domain}`, requestConfig);

  if(response.status !== 200) {
    throw new Error(`Failed to get identity provider for ${domain}`);
  }
  const body = await response.json();
  return body.name;
};

export const parentMessageReciever = (event: { data: any }) => {
  const { data } = event;

  if (data?.provider) {
    Auth.federatedSignIn({ customProvider: data.provider });
  }
};

export const popupInitiation = async (provider: string) => {

  const popUpIdentityProviderSupplier = (event: MessageEvent) => {
    if (event.origin === window.location.origin) {
  
      const { data } = event;
  
      if (data?.command === 'provider_request') {
        window.postMessage({ provider });
        window.removeEventListener('message', popUpIdentityProviderSupplier)
      }
    }
  }
  window.addEventListener('message', popUpIdentityProviderSupplier);

  window.open(`${window.location.origin}/login`, '_unfencedTop');
};
