import { createBrowserRouter } from 'react-router-dom';
import ProtectedRoute, { ProtectedRouteCreate, ProtectedRouteUpdate, ProtectedRouteRead } from './components/protectedRoute';
import ErrorPage from './error-page';
import AuditLog from './routes/audit-log';
import ChatbotTesting from './routes/chatbot-testing';
import Closures from './routes/closures';
import ClosuresCopy from './routes/closures/copy';
import ClosuresCreate from './routes/closures/create';
import ClosuresEdit from './routes/closures/edit';
import ContactSearch from './routes/contact-search';
import ContactSearchView from './routes/contact-search/view';
import DialPlan from './routes/dial-plan';
import DialPlanCopy from './routes/dial-plan/copy';
import DialPlanCreate from './routes/dial-plan/create';
import DialPlanEdit from './routes/dial-plan/edit';
import Documents from './routes/documents';
import DocumentsCreate from './routes/documents/create';
import Home from './routes/home';
import Instances from './routes/instances';
import InstancesCreate from './routes/instances/create';
import InstancesUpdate from './routes/instances/edit';
import Messages from './routes/messages';
import MessagesCopy from './routes/messages/copy';
import MessagesCreate from './routes/messages/create';
import MessagesEdit from './routes/messages/edit';
import PromptEngineering from './routes/prompt-engineering';
import PromptEngineeringCreate from './routes/prompt-engineering/create';
import PromptEngineeringEdit from './routes/prompt-engineering/edit';
import Roles from './routes/roles';
import CreateOrEditRole from './routes/roles/createOrEdit';
import EditRoleUsers from './routes/roles/users';
import Root from './routes/root';
import Settings from './routes/settings';
import SurveyList from './routes/surveys';
import SurveyCreate from './routes/surveys/create';
import SurveyEdit from './routes/surveys/edit';
import SurveySettings from './routes/surveys/settings';
import Tickets from './routes/tickets';
import TicketsCreate from './routes/tickets/create';
import UserAdmin from './routes/user-admin';
import UserAdminCreate from './routes/user-admin/create';
import Utilities from './routes/utilities';
import Visualizations from './routes/visualizations';
import VisualizationsCreate from './routes/visualizations/create';
import { VisualizationsEdit } from './routes/visualizations/edit';
import VisualizationsView from './routes/visualizations/view';
import { ResourceTypes } from './types/rolePermissions';
import { SurveyResults } from './routes/surveys/results';
import VisualizationsCopy from './routes/visualizations/copy';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <Home />,
      },
      {
        path: 'dial-plan',
        element: (
          <ProtectedRoute resourceType={ResourceTypes.DialPlan}>
            <DialPlan />
          </ProtectedRoute>
        ),
      },
      {
        path: 'dial-plan/create',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.DialPlan}>
            <DialPlanCreate />
          </ProtectedRouteCreate>
        ),
      },
      {
        path: 'dial-plan/edit/:featureId',
        element: (
          <ProtectedRouteUpdate resourceType={ResourceTypes.DialPlan}>
            <DialPlanEdit />
          </ProtectedRouteUpdate>
        ),
      },
      {
        path: 'dial-plan/copy/:featureId',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.DialPlan}>
            <DialPlanCopy />
          </ProtectedRouteCreate>
        ),
      },
      {
        path: 'messages',
        element: (
          <ProtectedRoute resourceType={ResourceTypes.Messages}>
            <Messages />
          </ProtectedRoute>
        ),
      },
      {
        path: 'messages/create',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.Messages}>
            <MessagesCreate />
          </ProtectedRouteCreate>
        ),
      },
      {
        path: 'messages/edit/:featureId',
        element: (
          <ProtectedRouteUpdate resourceType={ResourceTypes.Messages}>
            <MessagesEdit />
          </ProtectedRouteUpdate>
        ),
      },
      {
        path: 'messages/copy/:featureId',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.Messages}>
            <MessagesCopy />
          </ProtectedRouteCreate>
        ),
      },
      {
        path: 'closures',
        element: (
          <ProtectedRoute resourceType={ResourceTypes.Closures}>
            <Closures />
          </ProtectedRoute>
        ),
      },
      {
        path: 'closures/create',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.Closures}>
            <ClosuresCreate />
          </ProtectedRouteCreate>
        ),
      },
      {
        path: 'closures/edit/:featureId',
        element: (
          <ProtectedRouteUpdate resourceType={ResourceTypes.Closures}>
            <ClosuresEdit />
          </ProtectedRouteUpdate>
        ),
      },
      {
        path: 'closures/copy/:featureId',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.Closures}>
            <ClosuresCopy />
          </ProtectedRouteCreate>
        ),
      },
      {
        path: 'visualizations',
        element: (
          <ProtectedRouteRead resourceType={ResourceTypes.Dashboards}>
            <Visualizations />
          </ProtectedRouteRead>
        ),
      },
      {
        path: 'visualizations/create',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.Dashboards}>
            <VisualizationsCreate />
          </ProtectedRouteCreate>
        ),
      },
      {
        path: 'visualizations/edit/:featureId',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.Dashboards}>
            <VisualizationsEdit />
          </ProtectedRouteCreate>
        ),
      },      {
        path: 'visualizations/copy/:featureId',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.Dashboards}>
            <VisualizationsCopy />
          </ProtectedRouteCreate>
        ),
      },
      {
        path: 'visualizations/view/:featureId',
        element: <VisualizationsView />,
      },
      {
        path: 'documents',
        element: <Documents />,
      },
      {
        path: 'documents/create',
        element: <DocumentsCreate />,
      },
      {
        path: 'prompt-engineering',
        element: <PromptEngineering />,
      },
      {
        path: 'prompt-engineering/edit/:featureId',
        element: <PromptEngineeringEdit />,
      },
      {
        path: 'prompt-engineering/create',
        element: <PromptEngineeringCreate />,
      },
      {
        path: 'chatbot-testing',
        element: <ChatbotTesting />,
      },
      {
        path: 'user-admin',
        element: <UserAdmin />,
      },
      {
        path: 'user-admin/create',
        element: <UserAdminCreate />,
      },
      {
        path: 'audit-log',
        element: <AuditLog />,
      },
      {
        path: 'contact-search',
        element: (
          <ProtectedRouteRead resourceType={ResourceTypes.ContactSearch}>
            <ContactSearch />
          </ProtectedRouteRead>
        ),
      },
      {
        path: 'contact-search/view/:contactId',
        element: (
          <ProtectedRouteRead resourceType={ResourceTypes.ContactSearch}>
            <ContactSearchView />
          </ProtectedRouteRead>
        ),
      },
      {
        path: 'settings',
        element: <Settings />,
      },
      {
        path: 'utilities',
        element: <Utilities />,
      },
      {
        path: '*',
        element: <ErrorPage />,
      },
      {
        path: 'instances',
        element: <Instances />,
      },
      {
        path: 'instances/create',
        element: <InstancesCreate />,
      },
      {
        path: 'instances/edit/:instanceId',
        element: <InstancesUpdate />,
      },
      {
        path: 'tickets',
        element: <Tickets />,
      },
      {
        path: 'tickets/create',
        element: <TicketsCreate />,
      },
      {
        path: 'roles',
        element: <Roles />,
      },
      {
        path: 'roles/create',
        element: <CreateOrEditRole />,
      },
      {
        path: 'roles/edit/:featureId',
        element: <CreateOrEditRole />,
      },
      {
        path: 'roles/edit/:featureId/users',
        element: <EditRoleUsers />,
      },
      {
        path: 'surveys',
        element: (
          <ProtectedRoute resourceType={ResourceTypes.Surveys}>
            <SurveyList />
          </ProtectedRoute>
        ),
      },
      {
        path: 'surveys/settings',
        element: (
          <ProtectedRoute resourceType={ResourceTypes.Surveys}>
            <SurveySettings />
          </ProtectedRoute>
        ),
      },
      {
        path: 'surveys/edit/:featureId',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.Surveys}>
            <SurveyEdit />
          </ProtectedRouteCreate>
        ),
      },
      {
        path: 'surveys/create',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.Surveys}>
            <SurveyCreate />
          </ProtectedRouteCreate>
        ),
      },
      {
        path: 'surveys/results/:featureId',
        element: (
          <ProtectedRouteCreate resourceType={ResourceTypes.Surveys}>
            <SurveyResults />
          </ProtectedRouteCreate>
        ),
      },
    ],
  },
]);
