import React, { useCallback, useState } from 'react';
import { ColumnLayout, Container, Header, ContentLayout, SpaceBetween, Textarea, Form, Input, Button, FormField } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';
import useChangeDetector from '../../../utils/useChangeDetector';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlashInstance';
import { TagIcon } from '../../utilities/tagIcon';
import { isValidTag } from '../../../utils/validations';

export default function MessagesCreate() {
  // state to control changes made
  const { item, setItem } = useChangeDetector<any>({});

  // state
  const [resourceError, setResourceError] = useState<boolean>(true);
  const [resourceErrorMessage, setResourceErrorMessage] = useState<string>('');
  const [tagError, setTagError] = useState<boolean>(false);
  const [tagErrorMessage, setTagErrorMessage] = useState<string>();
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  // Hooks
  const navigate = useNavigate();
  const { handleApiWithFlash } = useApiWithFlash();

  // Functions to handle form changes
  const handleDropdownChange = useCallback(
    (field: any, value: any) => {
      setItem((prev: any) => ({ ...prev, [field]: value }));
    },
    [setItem],
  );

  const validateTag = (value: string) => {
    // Define patterns
    const validTag = isValidTag(value);

    if (!validTag) {
      setTagError(true);
      setTagErrorMessage('Only lowercase alphanumeric, - _ and + supported');
    } else {
      setTagError(false);
      setTagErrorMessage('');
    }
  };

  const validateResource = (value: string) => {
    // Define patterns
    const generalPattern = /^[a-zA-Z0-9+_@.-]+$/;

    if (value.length >= 6) {
      if (!generalPattern.test(value)) {
        setResourceError(true);
        setResourceErrorMessage('Only alphanumeric, - _ @ . and + supported');
      } else {
        setResourceError(false);
        setResourceErrorMessage('');
      }
    }
  };

  const handleResourceChange = (value: string) => {
    validateResource(value);
    handleDropdownChange('feature', value);
  };

  const handleTagChange = (tag: string) => {
    handleDropdownChange('tag', tag);
    validateTag(tag);
  };

  const handleDescriptionChange = (value: string) => {
    handleDropdownChange('description', value);
  };

  // Handle form submission
  async function handleSubmitForm() {
    setFormSubmitted(true);

    const payload = {
      description: item.description || '',
      tag: item.tag,
    };

    const response = await handleApiWithFlash(`/messages/${item.feature}`, 'POST', {
      successMessage: `Successfully created message group  ${item.feature}`,
      errorMessage: `Error creating message group ${item.feature}`,
      data: payload,
    });

    if (response?.success) {
      navigate(`/messages/edit/${item.feature}`);
    } else {
      navigate(`/messages`);
    }
  }

  const canSubmit = !resourceError && !tagError;

  return (
    <ContentLayout header={<Header variant='h1'></Header>}>
      <Container
        header={
          <Header
            variant='h2'
            description='Use this form to create a new Message Group. You will add messages and attributes on the next screen'
          >
            Create a new Message Group
          </Header>
        }
      >
        <form
          onSubmit={(e) => {
            console.log(e);
            e.preventDefault();
            handleSubmitForm();
          }}
        >
          <Form
            variant='embedded'
            actions={
              <SpaceBetween
                direction='horizontal'
                size='xs'
              >
                <Button
                  formAction='none'
                  variant='link'
                  onClick={() => navigate('/messages')}
                >
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  loading={formSubmitted}
                  disabled={!canSubmit}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween
              direction='vertical'
              size='xl'
            >
              <ColumnLayout
                columns={2}
                variant='text-grid'
              >
                <FormField
                  constraintText={'Only alphanumeric, - _ @ . and + supported'}
                  label={'Provide a message group name'}
                  errorText={resourceErrorMessage}
                >
                  <Input
                    onChange={({ detail }) => handleResourceChange(detail.value)}
                    value={item?.feature}
                  />
                </FormField>
                <FormField
                  constraintText={'Only alphanumeric, - _ and + supported'}
                  errorText={tagErrorMessage}
                  label={
                    <>
                      Tag your message group <TagIcon />
                    </>
                  }
                >
                  <Input
                    onChange={({ detail }) => handleTagChange(detail.value)}
                    value={item?.tag ?? ''}
                  />
                </FormField>
              </ColumnLayout>
              <FormField
                label='Description'
                stretch
              >
                <Textarea
                  onChange={({ detail }) => handleDescriptionChange(detail.value)}
                  value={item?.description}
                />
              </FormField>
            </SpaceBetween>
          </Form>
        </form>
      </Container>
    </ContentLayout>
  );
}
