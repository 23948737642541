import Header from '@cloudscape-design/components/header';
import { relativeRangeLabels } from '../../../../../../objects/dateRanges';
import { useDashboardContext } from '../../context/dashboardContext';

export function ChartHeader() {
  const { dashboard } = useDashboardContext();

  let humanDateRange = 'Custom range';

  if (dashboard.data.period.type === 'relative') {
    const { unit, amount } = dashboard.data.period;
    const key = `${unit}-${amount}`;
    humanDateRange = relativeRangeLabels[key] || `Last ${amount} ${unit}${amount !== 1 ? 's' : ''}`;
  }

  console.log({ humanDateRange });

  return (
    <Header
      variant='h2'
      description={`% for ${humanDateRange}`}
    >
      Abandon Rate
    </Header>
  );
}
