import { Auth } from 'aws-amplify';

export async function signOut() {

  const isInIframe = window.location !== window.parent.location;
  try {
    if(isInIframe) {

      const userSession = await Auth.currentSession();
      const accessToken = userSession.getAccessToken();
      const keyPrefix = `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_WEB_CLIENT_ID}.${accessToken.payload.username}`;
      localStorage.removeItem(`${keyPrefix}.idToken`);
      localStorage.removeItem(`${keyPrefix}.accessToken`);
      localStorage.removeItem(`${keyPrefix}.refreshToken`);
      localStorage.removeItem(`CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_WEB_CLIENT_ID}.LastAuthUser`);
      localStorage.removeItem(`amplify-redirected-from-hosted-ui`);
      localStorage.removeItem(`amplify-signin-with-hostedUI`);
      window.location.reload();
    } else {
    await Auth.signOut();
    }
  } catch (error) {
    console.error('error signing out: ', error);
  }
}
