import { View, Heading, useAuthenticator, Button, Text, Image, useTheme } from '@aws-amplify/ui-react';
import { useEffect } from 'react';
import LoginHeader from './components/login';

export const authComponents = {
  Header() {
    return (
      <View textAlign='center'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Image
            alt='logo'
            src={'/dark.svg'}
            width={250}
            height={75}
          />
        </div>
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View
        textAlign='center'
        padding={tokens.space.large}
      >
        <Text color={tokens.colors.neutral[80]}>&copy; All rights reserved CX Studios Ltd</Text>
      </View>
    );
  },

  SignIn: {
    Header: LoginHeader, 
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign='center'>
          <Button
            fontWeight='normal'
            onClick={toResetPassword}
            size='small'
            variation='link'
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.large} 0 0 ${tokens.space.large}`}
          level={3}
        >
          Register OTP
        </Heading>
      );
    },
    Footer() {
      return <Text>TIP: Scan QR using Google / Microsoft Authenticator mobile app</Text>;
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();

      useEffect(() => {
        const element = document.querySelector('[name="confirmation_code"]');
        if (element && element instanceof HTMLElement) {
          element.focus();
        }
      });
      return (
        <Heading
          padding={`${tokens.space.large} 0 0 ${tokens.space.large}`}
          level={3}
        >
          One time passcode:
        </Heading>
      );
    },
    Footer() {
      return <Text>TIP: Google / Microsoft Authenticator</Text>;
    },
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.large} 0 0 ${tokens.space.large}`}
          level={3}
        >
          Password reset:
        </Heading>
      );
    },
    Footer() {
      return <Text />;
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.large} 0 0 ${tokens.space.large}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text></Text>;
    },
  },
};
export const authFormFields = {
  signIn: {
    username: {
      placeholder: 'Enter your email:',
      label: '',
    },
    password: {
      label: '',
      placeholder: 'Enter your Password:',
    },
  },

  forceNewPassword: {
    password: {
      placeholder: 'Enter your Password:',
    },
  },
  resetPassword: {
    username: {
      placeholder: 'Enter your email:',
      label: '',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: 'Enter your email Code:',
      // label: 'New Label',
      isRequired: true,
    },
    confirm_password: {
      placeholder: 'Enter your Password Please:',
    },
  },
  setupTOTP: {
    QR: {
      totpIssuer: process.env.REACT_APP_ENV === 'prod' ? 'cato.cx.studio' : `Cato-${process.env.REACT_APP_ENV}`,
    },
    confirmation_code: {
      label: '',
      placeholder: 'Enter your code:',
      isRequired: true,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: '',
      placeholder: 'Enter your authenticator Code:',
      isRequired: true,
    },
  },
};
