import React from 'react';
import { CollectionPreferencesProps, StatusIndicator, TableProps } from '@cloudscape-design/components';
import { secondsToHHMM } from '../../../../../../utils/timeUtils';
import { TablePreferences } from '../../../../../../utils/tablePreferences';
import { PreferenceProps } from '../../../../../../types/table';
import { getStatusIndicatorType } from './contactTraceRecord.helpers';
import { ContactTraceRecordSnapshot } from './types';


export const tableColumns: TableProps.ColumnDefinition<ContactTraceRecordSnapshot>[] = [
  {
    id: 'eventType',
    header: 'eventType',
    cell: (item) => item.eventType || '',
    width: 100,
    isRowHeader: true,
  },
  {
    id: 'username',
    header: 'Username',
    cell: (item) => item.currentAgentSnapshotConfigurationUsername || '',
    width: 150,
    isRowHeader: true,
  },
  {
    id: 'firstName',
    header: 'First Name',
    cell: (item) => item.currentAgentSnapshotConfigurationFirstName || '',
    width: 100,
    isRowHeader: true,
  },
  {
    id: 'lastName',
    header: 'Last Name',
    cell: (item) => item.currentAgentSnapshotConfigurationLastName || '',
    width: 100,
    isRowHeader: true,
  },
  {
    id: 'autoAccept',
    header: 'Auto Accept',
    cell: (item) => (item.currentAgentSnapshotConfigurationAutoAccept === "true" ? 'Yes' : 'No'),
    width: 100,
    isRowHeader: true,
  },
  {
    id: 'routingProfile',
    header: 'Routing Profile',
    cell: (item) => item.currentAgentSnapshotConfigurationRoutingProfileName || '',
    width: 150,
    isRowHeader: true,
  },
  {
    id: 'agentHierarchyGroupsLevel1',
    header: 'Hierarchy L1',
    cell: (item) => item.currentAgentSnapshotConfigurationAgentHierarchyGroupsL1 || '',
    width: 100,
    isRowHeader: true,
  },
  {
    id: 'agentHierarchyGroupsLevel2',
    header: 'Hierarchy L2',
    cell: (item) => item.currentAgentSnapshotConfigurationAgentHierarchyGroupsL2 || '',
    width: 100,
    isRowHeader: true,
  },
  {
    id: 'agentHierarchyGroupsLevel3',
    header: 'Hierarchy L3',
    cell: (item) => item.currentAgentSnapshotConfigurationAgentHierarchyGroupsL3 || '',
    width: 100,
    isRowHeader: true,
  },
  {
    id: 'agentHierarchyGroupsLevel4',
    header: 'Hierarchy L4',
    cell: (item) => item.currentAgentSnapshotConfigurationAgentHierarchyGroupsL4 || '',
    width: 100,
    isRowHeader: true,
  },
  {
    id: 'agentHierarchyGroupsLevel5',
    header: 'Hierarchy L5',
    cell: (item) => item.currentAgentSnapshotConfigurationAgentHierarchyGroupsL5 || '',
    width: 100,
    isRowHeader: true,
  },
  {
    id: 'agentStatusName',
    header: 'Status',
    cell: (item) => (
      <StatusIndicator type={getStatusIndicatorType(item.currentAgentSnapshotAgentStatusType, item.currentAgentSnapshotFirstContactState ?? '')}>
        {item.currentAgentSnapshotAgentStatusName}
      </StatusIndicator>
    ),
    width: 100,
    isRowHeader: true,
    sortingField: 'currentAgentSnapshotAgentStatusName',
  },
  {
    id: 'agentState',
    header: 'State',
    cell: (item) => item.currentAgentSnapshotFirstContactState ?? '',
    width: 100,
    isRowHeader: true,
  },
  {
    id: 'nextAgentStatus',
    header: 'Next Agent Status',
    cell: (item) => item.currentAgentSnapshotNextAgentStatus ?? '',
    width: 100,
    isRowHeader: true,
  },
  {
    id: 'firstContactQueueName',
    header: 'Queue',
    cell: (item) => item.currentAgentSnapshotFirstContactQueueName ?? '',
    width: 100,
    isRowHeader: true,
  },
  {
    id: 'statusDuration',
    header: 'Status Duration',
    cell: (item) => {
      const currentStatus = item.currentAgentSnapshotAgentStatusType;
      const currentStatusTimestamp = item.currentAgentSnapshotAgentStatusStartTimestamp;
      const contacts = item.currentAgentSnapshotContacts;

      // Convert the current time to seconds
      const currentTimeInSeconds = Math.floor(Date.now() / 1000);

      // 1. If current status is "Offline", return null
      if (currentStatus === 'Offline') {
        return null;
      }

      // 2. If the custom state is active return the time since state change
      if (currentStatus === 'CUSTOM') {
        const eventTimestampInSeconds = Math.floor(new Date(currentStatusTimestamp).getTime() / 1000);
        const duration = currentTimeInSeconds - eventTimestampInSeconds;
        return secondsToHHMM(duration);
      }

      // 3. If currentAgentSnapshotContacts[0] exists, return the time since currentAgentSnapshotContacts[0].ConnectedToAgentTimestamp
      if (contacts?.[0]?.ConnectedToAgentTimestamp) {
        const contactTimestampInSeconds = Math.floor(new Date(contacts[0].ConnectedToAgentTimestamp).getTime() / 1000);
        const duration = currentTimeInSeconds - contactTimestampInSeconds;
        return secondsToHHMM(duration);
      }

      return null;
    },
    width: 100,
    isRowHeader: true,
  },
  {
    id: 'subStateDuration',
    header: 'Sub State Duration',
    cell: (item) => {
      const firstStateStartTimestamp = item.currentAgentSnapshotFirstContactStateStartTimestamp;

      // Convert the current time to seconds
      const currentTimeInSeconds = Math.floor(Date.now() / 1000);

      // 1. If current status is "Offline", return null
      if (!item.currentAgentSnapshotFirstContactState) {
        return null;
      } else {
        const eventTimestampInSeconds = Math.floor(new Date(firstStateStartTimestamp).getTime() / 1000);
        const duration = currentTimeInSeconds - eventTimestampInSeconds;
        return secondsToHHMM(duration);
      }
    },
    width: 100,
    isRowHeader: true,
  },
];

const CONTENT_DISPLAY_OPTIONS = [
  { id: 'eventType', label: 'Event Type', alwaysVisible: false },
  { id: 'username', label: 'User Name', alwaysVisible: false },
  { id: 'firstName', label: 'First Name', alwaysVisible: false },
  { id: 'lastName', label: 'Last Name', alwaysVisible: false },
  { id: 'autoAccept', label: 'Auto Accept', alwaysVisible: false },
  { id: 'routingProfile', label: 'Routing Profile', alwaysVisible: false },
  { id: 'agentHierarchyGroupsLevel1', label: 'Hierarchy L1', alwaysVisible: false },
  { id: 'agentHierarchyGroupsLevel2', label: 'Hierarchy L2', alwaysVisible: false },
  { id: 'agentHierarchyGroupsLevel3', label: 'Hierarchy L3', alwaysVisible: false },
  { id: 'agentHierarchyGroupsLevel4', label: 'Hierarchy L4', alwaysVisible: false },
  { id: 'agentHierarchyGroupsLevel5', label: 'Hierarchy L5', alwaysVisible: false },
  { id: 'agentStatusName', label: 'Status', alwaysVisible: false },
  { id: 'agentState', label: 'Agent State', alwaysVisible: false },
  { id: 'agentStatusType', label: 'Status Type', alwaysVisible: false },
  { id: 'nextAgentStatus', label: 'Next Agent Status', alwaysVisible: false },
  { id: 'firstContactQueueName', label: 'First Contact Queue Name', alwaysVisible: false },
  { id: 'statusDuration', label: 'Status Duration', alwaysVisible: false },
  { id: 'subStateDuration', label: 'Sub State Duration', alwaysVisible: false },
];
export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 rows' },
  { value: 30, label: '30 rows' },
  { value: 50, label: '50 rows' },
];

export const DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  pageSize: 10,
  contentDisplay: [
    { id: 'username', visible: true },
    { id: 'firstName', visible: true },
    { id: 'lastName', visible: true },
    { id: 'routingProfile', visible: true },
    { id: 'agentStatusName', visible: true },
    { id: 'statusDuration', visible: true },
    { id: 'agentState', visible: true },
    { id: 'agentStatusType', visible: true },
    { id: 'subStateDuration', visible: true },
    { id: 'nextAgentStatus', visible: true },
    { id: 'firstContactQueueName', visible: true },
    { id: 'autoAccept', visible: true },
    { id: 'agentHierarchyGroupsLevel1', visible: false },
    { id: 'agentHierarchyGroupsLevel2', visible: false },
    { id: 'agentHierarchyGroupsLevel3', visible: false },
    { id: 'agentHierarchyGroupsLevel4', visible: false },
    { id: 'agentHierarchyGroupsLevel5', visible: false },
    { id: 'eventType', visible: false },
  ],
  wrapLines: false,
  stripedRows: false,
  contentDensity: 'compact',
  stickyColumns: { first: 1, last: 0 },
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled = false,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
}: PreferenceProps) => <TablePreferences {...{ preferences, setPreferences, disabled, pageSizeOptions, contentDisplayOptions }} />;
