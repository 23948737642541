import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Table, Header, SpaceBetween, Button, DatePicker, Textarea, TimeInput, StatusIndicator, Select, Icon } from '@cloudscape-design/components';
import { faLanguage, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlannedClosure, SingleClosureAPIResponse } from '../../../../types/closures';
import TranslationModal from '../../../messages/edit/components/translationModal';
import { useInlinePlannedEdit } from '../../lib/useInlinePlannedEdit';
import sortEvents from '../../../../utils/sortevents';
import { isDateExpired } from '../../../../utils/isDateExpired';
import { useAppSession } from '../../../../utils/hooks/sessionContext';

export interface PlannedClosuresProps {
  plannedClosures: PlannedClosure[];
  setItem: React.Dispatch<any>;
  item: SingleClosureAPIResponse;
}

const PlannedClosures: React.FC<PlannedClosuresProps> = ({ plannedClosures, setItem, item }) => {
  const { companyName } = useAppSession();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedMessageKey, setSelectedMessageKey] = useState<string | null>(null);
  const { handlePlannedEdit, addPlannedRow, removePlannedRow } = useInlinePlannedEdit(setItem);
  const timezone = item.data.timezone;
  const sortedPlannedClosures: PlannedClosure[] = sortEvents(plannedClosures, timezone);
  const openModalWithMessage = (key: any) => {
    setSelectedMessageKey(key);
    setModalOpen(true);
  };

    const importUKBankHolidays = async () => {
      const response = await fetch('https://www.gov.uk/bank-holidays.json');
      const json = await response.json();
  
      const englandAndWalesBankHolidays = json['england-and-wales'].events.filter(({ date }: any) => {
        const isFutureDate = new Date(date).getTime() > new Date().getTime();
        const isAlreadyInList = plannedClosures.some((closure) => closure.date === date);
  
        return isFutureDate && !isAlreadyInList;
      });
  
      setItem((prevItem: SingleClosureAPIResponse) => {
  
        const formattedPlannedClosures =  englandAndWalesBankHolidays.map((bankHoliday: any) => {
          return {
            date: bankHoliday.date,
            description: bankHoliday.title,
            startTime: '00:00',
            endTime: '23:59',
            recurrence: 'none',
            recurrenceEnd: '',
            message: [
              {
                lang: 'default',
                message: [
                  {
                    children: [
                      {
                        text: `Thank you for contacting ${companyName}, we are currently closed for ${bankHoliday.title}`,
                      },
                    ],
                    type: 'paragraph',
                  },
                ],
              },
            ],
            key: uuidv4(),
        };
      });
  
        return {
          ...prevItem,
          data: {
            ...prevItem.data,
            plannedClosures: [...prevItem.data.plannedClosures, ...formattedPlannedClosures],
          },
        }
      });
    };

  return (
    <>
      <SpaceBetween
        direction='vertical'
        size='xs'
      >
        <Table
          header={
            <Header
              variant='h2'
              actions={
                <SpaceBetween
                  direction='horizontal'
                  size='xs'
                >
                  {item.data.timezone === 'Europe/London' && <Button onClick={importUKBankHolidays}>Import UK Bank Holidays <Icon name="gen-ai" /></Button>}
                  <Button
                    variant='primary'
                    onClick={() => {
                      addPlannedRow('plannedClosure');
                    }}
                  >
                    Add
                  </Button>
                </SpaceBetween>
              }
            >
              Planned Closures
            </Header>
          }
          wrapLines={true}
          stickyColumns={{ first: 1, last: 0 }}
          columnDefinitions={[
            {
              id: 'description',
              header: 'Description',
              cell: (item) => {
                return (
                  <>
                    {item.description}
                    {isDateExpired(item, timezone) && <StatusIndicator type='warning'>Expired</StatusIndicator>}
                  </>
                );
              },
              width: 200,
              minWidth: 200,
              isRowHeader: true,
              editConfig: {
                ariaLabel: 'description',
                editIconAriaLabel: 'editable',
                errorIconAriaLabel: 'description Error',
                editingCell: (message, cellContext) => {
                  return (
                    <Textarea
                      value={cellContext.currentValue ?? message.description}
                      onChange={({ detail }) => {
                        cellContext.setValue(detail.value);
                      }}
                    />
                  );
                },
              },
            },
            {
              id: 'date',
              header: 'Date',
              cell: (item) => item.date || '-',
              width: 250,
              minWidth: 250,
              isRowHeader: true,
              editConfig: {
                ariaLabel: 'date',
                editIconAriaLabel: 'editable',
                errorIconAriaLabel: 'Date Error',
                validation: (_item, value) => {
                  if (!value) return 'Date cannot be empty';
                  return undefined;
                },
                editingCell: (message, cellContext) => {
                  return (
                    <DatePicker
                      value={cellContext.currentValue ?? message.date}
                      onChange={({ detail }) => {
                        cellContext.setValue(detail.value);
                      }}
                      expandToViewport
                      openCalendarAriaLabel={(selectedDate) => 'Choose closure date' + (selectedDate ? `, selected date is ${selectedDate}` : '')}
                      placeholder='YYYY/MM/DD'
                    />
                  );
                },
              },
            },
            {
              id: 'startTime',
              header: 'Start',
              cell: (item) => item.startTime || '-',
              width: 150,
              minWidth: 150,
              isRowHeader: true,
              editConfig: {
                ariaLabel: 'startTime',
                editIconAriaLabel: 'editable',
                errorIconAriaLabel: 'startTime Error',
                validation: (_item, value) => {
                  if (!value) return 'Start time cannot be empty';
                  return undefined;
                },
                editingCell: (message, cellContext) => {
                  return (
                    <TimeInput
                      value={cellContext.currentValue ?? message.startTime}
                      onChange={({ detail }) => {
                        cellContext.setValue(detail.value);
                      }}
                      format='hh:mm'
                      placeholder='hh:mm'
                    />
                  );
                },
              },
            },
            {
              id: 'endTime',
              header: 'End',
              cell: (item) => item.endTime || '-',
              width: 150,
              minWidth: 150,
              isRowHeader: true,
              editConfig: {
                ariaLabel: 'endTime',
                editIconAriaLabel: 'editable',
                errorIconAriaLabel: 'endT ime Error',
                validation: (item, value) => {
                  if (!value) return 'End time cannot be empty';
                  return undefined;
                },
                editingCell: (message, cellContext) => {
                  return (
                    <TimeInput
                      value={cellContext.currentValue ?? message.endTime}
                      onChange={({ detail }) => {
                        cellContext.setValue(detail.value);
                      }}
                      format='hh:mm'
                      placeholder='hh:mm'
                    />
                  );
                },
              },
            },
            {
              id: 'recurrence',
              header: 'Recurrence',
              cell: (item) => item.recurrence.charAt(0).toUpperCase() + item.recurrence.slice(1) || '-',
              width: 200,
              minWidth: 200,
              isRowHeader: true,
              editConfig: {
                ariaLabel: 'recurrence',
                editIconAriaLabel: 'editable',
                errorIconAriaLabel: 'recurrence Error',
                editingCell: (message, cellContext) => {
                  return (
                    <Select
                      selectedOption={{
                        label:
                          ((cellContext.currentValue ?? message.recurrence) || 'None').charAt(0).toUpperCase() +
                          ((cellContext.currentValue ?? message.recurrence) || 'None').slice(1),
                        value: cellContext.currentValue ?? message.recurrence,
                      }}
                      onChange={
                        ({ detail }) => cellContext.setValue(detail.selectedOption.value) // Assuming you're only interested in storing the value
                      }
                      options={[
                        { label: 'None', value: 'none' },
                        { label: 'Daily', value: 'daily' },
                        { label: 'Weekly', value: 'weekly' },
                        // { label: "Monthly", value: "monthly" }, https://cxstudio.atlassian.net/browse/CATO-579
                        { label: 'Yearly', value: 'yearly' },
                      ]}
                      expandToViewport
                    />
                  );
                },
              },
            },
            {
              id: 'recurrenceEnd',
              header: 'Recurrence End',
              cell: (item) => item.recurrenceEnd || '-',
              width: 250,
              minWidth: 250,
              isRowHeader: true,
              editConfig: {
                ariaLabel: 'date',
                editIconAriaLabel: 'editable',
                errorIconAriaLabel: 'Date Error',
                validation: (item, _value) => {
                  if (item.recurrence === 'none') {
                    return 'Please select a recurrence before setting an end date.';
                  }
                  return undefined;
                },
                editingCell: (message, cellContext) => {
                  return (
                    <DatePicker
                      value={cellContext.currentValue ?? message.recurrenceEnd}
                      onChange={({ detail }) => {
                        cellContext.setValue(detail.value);
                      }}
                      expandToViewport
                      openCalendarAriaLabel={(selectedDate) => 'Choose closure date' + (selectedDate ? `, selected date is ${selectedDate}` : '')}
                      placeholder='YYYY/MM/DD'
                      disabled={message.recurrence === 'none'} // Add this line
                    />
                  );
                },
              },
            },
            {
              id: 'default',
              header: 'Default Message',
              cell: (item) => {
                const defaultMessage = item.message.find((m) => m.lang === 'default');
                return defaultMessage?.message[0]?.children[0]?.text ?? '-';
              },
              width: 400,
              minWidth: 400,
              isRowHeader: true,
              editConfig: {
                ariaLabel: 'Name',
                editIconAriaLabel: 'editable',
                errorIconAriaLabel: 'Name Error',
                editingCell: (message, cellContext) => {
                  const defaultMessagePath = message.message.find((m) => m.lang === 'default')?.message[0]?.children[0]?.text;
                  return (
                    <Textarea
                      autoFocus={true}
                      value={cellContext.currentValue ?? defaultMessagePath}
                      onChange={(event) => {
                        cellContext.setValue(event.detail.value);
                      }}
                    />
                  );
                },
              },
            },
            {
              id: 'translations',
              header: 'Translations',
              cell: (message) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faLanguage}
                    style={{ cursor: 'pointer' }}
                    onClick={() => openModalWithMessage(message.key)}
                    title='View Translations'
                  />
                </div>
              ),
              width: 50,
              minWidth: 50,
            },
            {
              id: 'remove',
              header: 'Remove',
              cell: (item) => (
                <FontAwesomeIcon
                  icon={faTrash}
                  style={{ color: '#ff0000', cursor: 'pointer' }}
                  onClick={(event) => removePlannedRow(event, item.key, 'closure')}
                />
              ),
              width: 30,
              minWidth: 30,
            },
          ]}
          submitEdit={(item, column, newValue) => {
            handlePlannedEdit(column.id as string, item, column, newValue);
          }}
          items={sortedPlannedClosures}
          loadingText='Loading planned closures...'
        />
      </SpaceBetween>
      {selectedMessageKey && (
        <TranslationModal
          visible={isModalOpen}
          setVisible={setModalOpen}
          messageKey={selectedMessageKey}
          item={item}
          setItem={setItem}
          messagesPath='data.plannedClosures'
        />
      )}
    </>
  );
};

export default PlannedClosures;
