import 'react-grid-layout/css/styles.css';
import ReactGridLayout from 'react-grid-layout';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import { useDashboardContext } from '../../context/dashboardContext';
import { useDashboardManager } from '../../hooks/useDashboardManager';
import { GridWidget } from './widget';
import { useAppSession } from '../../../../../../utils/hooks/sessionContext';
import { WidgetDataRepresentation } from '../../widgets/interfaces';
import { MockContextProvider } from '../../context/mockContextProvider';
import useResizeObserver from '@react-hook/resize-observer';
import { allWidgets } from '../../widgets';
import { useInitialise } from '../../../../../../utils/hooks/useInitialise';

const paddingHeight = Number(styles.rowPadding.replace('px', ''));
const rowHeight = Number(styles.rowHeight.replace('px', ''));
const amountOfColumns = Number(styles.gridColumns);

function getContentWidth(element: Element) {
  return element.getBoundingClientRect().width;
}

export const Grid = () => {
  const { layout, dashboard } = useDashboardContext();
  const { handleLayoutChange, addWidget } = useDashboardManager();
  const appSession = useAppSession();
  const [isInMotion, setIsInMotion] = useState<boolean>(false);
  const [width, setWidth] = useState(1000);

  useEffect(() => {

    setIsInMotion(!!appSession?.activeWidget)
  }, [appSession?.activeWidget]);

  const detailElement = document.getElementById('detail');

  useInitialise(() => {
    !!detailElement && setWidth(getContentWidth(detailElement));
  });

  useResizeObserver(detailElement, (entry) => {
    const shouldSetWidth = entry.contentRect.width !== width;
    if (shouldSetWidth) {

      setWidth(entry.contentRect.width);
    }
  });

  const onDragStart = () => {
    setIsInMotion(true);
  };
  const onResizeStart = () => {
    setIsInMotion(true);
  };
  const onDragStop = () => {
    setIsInMotion(false);
  };
  const onResizeStop = () => {
    setIsInMotion(false);
  };

  const responsiveGridProps: ReactGridLayout.ReactGridLayoutProps = {
    width,
    className: (isInMotion && styles.isInMotion) || '',
    containerPadding: [paddingHeight, paddingHeight],
    layout: layout ?? [],
    margin: [paddingHeight, paddingHeight],
    cols: amountOfColumns,
    rowHeight,
    compactType: 'vertical',
    draggableHandle: '.dragger',
    isDroppable: true,
    autoSize: true,
    useCSSTransforms: true,
    onDragStart,
    onResizeStart,
    onDragStop,
    onResizeStop,
    droppingItem: { ...(appSession?.activeWidget?.size || { i: 'd', w: 3, h: 3 }) },
    onDrop: (layout, item) => {
      setIsInMotion(false);
      addWidget(layout, item);
    },
    onLayoutChange(currentLayout) {
      handleLayoutChange(currentLayout);
    },
  };

  const renderItem = ({ templateType, id }: WidgetDataRepresentation) => {
    if (!templateType || !id) {
      return <></>;
    }

    const widgetData = {
      ...allWidgets[templateType],
    };

    const Wrapper = widgetData.data.provider ?? MockContextProvider;

    const props = {
      widgetId: id,
    };

    return (
      <Wrapper {...props}>
        <GridWidget {...props} />
      </Wrapper>
    );
  };

  const readyToRender = layout.length === dashboard.data?.items.length;
  const addingNewItem = isInMotion && layout.length === dashboard.data?.items.length - 1;

  if (!readyToRender && !addingNewItem) {
    return <></>;
  }

  return (
    <ReactGridLayout {...responsiveGridProps}>
      {dashboard.data?.items?.map((item) => (
        <div
          className={styles.gridItemContainer}
          key={item.id}
        >
          {renderItem(item)}
        </div>
      ))}
    </ReactGridLayout>
  );
};
