import React, { createContext, useMemo, useState } from 'react';
import { Spinner } from '@cloudscape-design/components';
import { WidgetConfig, WidgetContentProps } from '../interfaces';
import GetWidgetData from '../../../../../../utils/getWidgetData';
import { useDashboardManager } from '../../hooks/useDashboardManager';
import { allContent, Content, WidgetPreferences } from '../preferences';
import Alert from '@cloudscape-design/components/alert';
import { useDashboardContext } from '../../context/dashboardContext';
import styles from './abandonStats.module.scss';
import { ChartHeader } from './abandonStats.header';

export const getAbandonStats: WidgetConfig = {
  definition: { defaultRowSpan: 1, defaultColumnSpan: 1, minRowSpan: 1 },
  data: {
    icon: 'number',
    title: 'Abandon Rate',
    description: 'Abandon rate, plus 7 day average',
    header: ChartHeader,
    content: AbandonStatsContent,
    provider: AbandonStatsProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

interface AbandonStatsWidgetContextType {
  visibleContent: ReadonlyArray<Content>;
  openPreferences: () => void;
}

const AbandonStatsWidgetContext = createContext<AbandonStatsWidgetContextType>({
  visibleContent: [],
  openPreferences: () => null,
});

function AbandonStatsProvider({ children, widgetId }: Readonly<{ children: React.ReactElement; widgetId: string }>) {
  const [preferencesVisible, setPreferencesVisible] = useState(false);
  const [visibleContent, setVisibleContent] = useState<ReadonlyArray<Content>>(allContent);

  const value = useMemo(
    () => ({
      visibleContent,
      openPreferences: () => setPreferencesVisible(true),
    }),
    [visibleContent],
  );

  return (
    <AbandonStatsWidgetContext.Provider {...{ value }}>
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: 'Operational metrics',
        actions: [{ text: 'Preferences', onClick: () => setPreferencesVisible(true) }],
      })}
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          preferences={visibleContent}
          onConfirm={(visibleContent) => {
            setVisibleContent(visibleContent);
            setPreferencesVisible(false);
          }}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}
    </AbandonStatsWidgetContext.Provider>
  );
}



export default function AbandonStatsContent({ widgetId }: WidgetContentProps) {
  const { getWidget } = useDashboardManager();
  const { dashboard } = useDashboardContext();
  const widget = getWidget(widgetId);
  const period = dashboard.data.period;

  const { data, isLoading, error } = GetWidgetData({ widget, period });

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Alert
        statusIconAriaLabel='Error'
        type='error'
        header='Unable to load widget'
      >
        {error.status}
      </Alert>
    );
  }

  const value = (data?.data?.value !== null && `${data.data.value}%`) || 'N/A';

  return (
    <div>
      <span className={styles.widgetMetric}>{value}</span>
    </div>
  );
}
