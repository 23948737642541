// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import Header from '@cloudscape-design/components/header';
import Box from '@cloudscape-design/components/box';

import * as icons from '../../utils/icons'
import styles from './styles.module.scss';
import { Icon } from '@cloudscape-design/components';
import { WidgetConfig } from '../../widgets/interfaces';
import { Layout } from 'react-grid-layout';
import { useAppSession } from '../../../../../../utils/hooks/sessionContext';

type IconName = keyof typeof icons;

interface PaletteItemProps {
    templateId: string;
    title: string;
    iconName: IconName;
    description: string;
    definition: WidgetConfig['definition']
}

function PaletteIcon({iconName}: Readonly<{
    iconName: IconName
}>) {
    const icon = icons[iconName];
    const altText = `${iconName} icon`;

    return (
            <img className="awsui-util-hide-in-dark-mode" src={icon.light} alt={altText}/>
    );
}

export function PaletteItem({templateId, title, iconName, description, definition}: Readonly<PaletteItemProps>) {

    const { setActiveWidget } = useAppSession();
    const handleDragStart = () => {

        const size: Layout = {
            w: 3 * (definition?.defaultColumnSpan ?? 1),
            h: 2 * (definition?.defaultRowSpan ?? 3),
            i: templateId,
            y: 0,
            x: 0
        };

        setActiveWidget({templateType: templateId, size});

    }

    const handleDragEnd = () => {
        setActiveWidget(undefined);
    }

    return (
        <section draggable={true} className={styles.palleteItem} onDragStart={handleDragStart} onDragEnd={handleDragEnd} role='presentation'>
            <Header headingTagOverride="h3" ><Icon name='drag-indicator' />{title}</Header> 
            <div className={styles.layout}>
                    <div>
                        <PaletteIcon iconName={iconName}/>
                    </div>
                    <Box variant="p">{description}</Box>
                </div>
        </section>
    );
}
