import { SelectProps } from '@cloudscape-design/components';
import { OptionDefinition, OptionGroup } from '@cloudscape-design/components/internal/components/option/interfaces';
import { InstanceType } from '../../types/instance';

type AwsRegionsType = {
  [key: string]: string;
};

type BuildableOptionGroup = OptionGroup & {
  options: Array<OptionDefinition>;
};

// Move to a config file
const awsRegions: AwsRegionsType = {
  'us-east-1': 'US East (N. Virginia)',
  'us-east-2': 'US East (Ohio)',
  'us-west-1': 'US West (N. California)',
  'us-west-2': 'US West (Oregon)',
  'ca-central-1': 'Canada (Central)',
  'eu-west-1': 'EU (Ireland)',
  'eu-central-1': 'EU (Frankfurt)',
  'eu-west-2': 'EU (London)',
  'eu-west-3': 'EU (Paris)',
  'eu-north-1': 'EU (Stockholm)',
  'ap-northeast-1': 'Asia Pacific (Tokyo)',
  'ap-northeast-2': 'Asia Pacific (Seoul)',
  'ap-southeast-1': 'Asia Pacific (Singapore)',
  'ap-southeast-2': 'Asia Pacific (Sydney)',
  'ap-south-1': 'Asia Pacific (Mumbai)',
  'sa-east-1': 'South America (São Paulo)',
  'us-gov-west-1': 'US Gov West 1',
  'us-gov-east-1': 'US Gov East 1',
};

const extractRegion = (arn: string): string => arn.split(':')[3];

export function extractSelectGroupItems(data: InstanceType[]): Array<SelectProps.OptionGroup> {
  const selectGroup = data.reduce((response: { [region: string]: BuildableOptionGroup }, instance: InstanceType) => {
    const region = extractRegion(instance.instanceArn);
    const regionName = awsRegions[region] || 'Other';

    if (!response[regionName]) {
      response[regionName] = {
        label: regionName,
        options: [],
      };
    }

    response[regionName].options.push({ label: instance.instanceName || instance.name, value: instance.instanceArn });

    return response;
  }, {});

  return Object.values(selectGroup);
}
