import React , { useState } from 'react';
import { FormField, Input, Toggle } from '@cloudscape-design/components';
import MultiRangeSlider, { ChangeResult } from 'multi-range-slider-react';
import * as awsui from '@cloudscape-design/design-tokens';

import styles from './thresholdPreferences.module.scss';
import { useInitialise } from '../../../../../../utils/hooks/useInitialise';

export interface SingleThresholdPreferences {
  max: number;
  maxValue: number;
  minValue: number;
  ascending: boolean;
}

interface ThresholdPreferencesProps {
  preferences: SingleThresholdPreferences;
  onChange: (newPreferences: SingleThresholdPreferences) => void;
}

const ThresholdPreferences: React.FC<ThresholdPreferencesProps> = ({ preferences, onChange }) => {

  const ascending = preferences?.ascending ?? false;
  const [selectedRange, setSelectedRange] = useState<ChangeResult>({
    min: 0,
    max: 100,
    maxValue: 100,
    minValue: 0,
  });

  useInitialise(() => {
    preferences && setSelectedRange({
      min: 0,
      max: preferences.max ?? 100,
      maxValue: preferences.maxValue ?? 100,
      minValue: preferences.minValue ?? 0,
    });
});

  const handleLimitChange = (event: ChangeResult) => {
    setSelectedRange(event);
    onChange({ ...preferences, ...event });
  };
  
  const sliderProps = {
    ...selectedRange,
    step: 1,
    onChange: handleLimitChange,
    ruler: false,
    style: { border: 'none', boxShadow: 'none', padding: '15px 10px' },
    barLeftColor: ascending ? awsui.colorChartsStatusPositive : awsui.colorChartsStatusHigh,
    barInnerColor: awsui.colorChartsStatusMedium,
    barRightColor: ascending ? awsui.colorChartsStatusHigh : awsui.colorChartsStatusPositive,
  };

  const handleRangeChange = (max: string) => {
    setSelectedRange((previousItem) => ({ ...previousItem, max: +max }));
    onChange({ ...preferences, max: +max });
   
  };

  const toggleOrder = (ascending: boolean) => {
    onChange({  ...preferences, ascending });
  };
  
  
  return (
    <>
    <MultiRangeSlider
        {...sliderProps} 
    />

    <div className={styles.inlineFormField}>
      <div>
         <FormField description='Toggle the metrics colour order'>
            <Toggle
              onChange={({ detail }) => toggleOrder(detail.checked)}
               checked={preferences.ascending}
              // checked={sliderOrder}
            >
              Switch
            </Toggle>
        </FormField>
      </div>

      <div>
          <FormField description='Set upper limit of metric'>
          <Input
                onChange={({ detail }) => handleRangeChange(detail.value)}
                value={`${selectedRange.max}`}
                type='number'
              />
          </FormField>
      </div>
    </div>
    </>
  );
};

export default ThresholdPreferences;
